import { useEffect, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { createPageUrl } from '../../app/urls';
import { useLocationList } from '../../contexts/LocationListContext/LocationListContext';
import { useFetchForecastByLocationId } from '../../data/forecast/hooks';
import { useFetchLocation } from '../../data/locations/hooks';
import { useFetchWarningsByLocationId } from '../../data/warnings/hooks';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { TUserLocationType } from '../../model/userLocation';
import { ClickableLink } from '../Clickable/Clickable';
import { EmptyText } from '../EmptyText/EmptyText';
import { Heading } from '../Heading/Heading';
import { WarningsIconGroup } from '../WarningsIconGroup/WarningsIconGroup';
import { WeatherLocationListEditPanel } from '../WeatherLocationListEditPanel/WeatherLocationListEditPanel';
import './WeatherLocationListItem.scss';
import { WeatherLocationListItem__EditButton } from './WeatherLocationListItem__EditButton';
import { WeatherLocationListItem__Forecast } from './WeatherLocationListItem__Forecast';
import { WeatherLocationListItem__Star } from './WeatherLocationListItem__Star';

interface IProps {
  type: TUserLocationType;
  index: number;
  locationId?: string;
  columns: Array<{ time?: string; short: string; long: string }>;
  customLocationName?: string;
  setEditLocationId?: (editLocationId: string) => void;
}

export function WeatherLocationListItem(props: IProps) {
  const { index, type, locationId, columns, customLocationName, setEditLocationId } = props;

  const localeCode = useLocaleCode();
  const { isFirstRender } = useAppState();

  const translate = useTranslate();
  const { data: location, error: locationError } = useFetchLocation({ locationId });
  const { data: forecast } = useFetchForecastByLocationId({
    locationId
  });
  const { data: warningList } = useFetchWarningsByLocationId({ locationId });
  const [currentCustomLocationName, setCurrentCustomLocationName] = useState<string | undefined>();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setCurrentCustomLocationName(customLocationName);
  }, [customLocationName]);

  const { removeVisitedLocation, removeFavouritedLocation } = useLocationList();

  // Remove locations from favourites or last visited that no longer exists in Stadnamn3
  useEffect(() => {
    if (
      isFirstRender === false &&
      locationId != null &&
      location == null &&
      locationError != null &&
      'status' in locationError &&
      // We get statusCode 410 (gone) from the API if the location no longer exists
      locationError.status === 410
    ) {
      if (type === 'visited') {
        removeVisitedLocation(locationId);
      }

      if (type === 'favourited') {
        removeFavouritedLocation({ locationId, locationName: 'unknown', translate, withoutNotification: true });
      }
    }
  }, [
    isFirstRender,
    locationId,
    locationError,
    location,
    type,
    translate,
    removeFavouritedLocation,
    removeVisitedLocation
  ]);

  function onEditPanelOpen() {
    setOpen(true);
  }

  function onEditPanelClose() {
    setOpen(false);
  }

  function onEditPanelCancel() {
    setOpen(false);
  }

  function onEditLocationName() {
    if (setEditLocationId != null) {
      setEditLocationId(locationId ?? '');
    }
  }

  const locationUrl = location
    ? createPageUrl({
        localeCode,
        pageId: 'forecast',
        subpageId: 'daily-table',
        locationId: location.id,
        urlPath: location.urlPath
      })
    : '';

  const buttonId = `weather-location-list-item__edit-button-${type}-${index}`;

  return (
    <div className="weather-location-list-item">
      <div className="weather-location-list-item__container">
        <span className="weather-location-list-item__location">
          {location != null ? (
            <Heading level="h3" size="4" className="weather-location-list-item__location-heading">
              <ClickableLink className="weather-location-list-item__location-link" href={locationUrl} key="link">
                {currentCustomLocationName != null ? (
                  <span className="weather-location-list-item__location-name">{currentCustomLocationName}</span>
                ) : (
                  <span className="weather-location-list-item__location-name">{location.name}</span>
                )}
              </ClickableLink>
            </Heading>
          ) : (
            <EmptyText
              className="weather-location-list-item__location-heading"
              type="span"
              lengths={[10, 13, 17, 15, 14]}
              index={index}
            ></EmptyText>
          )}
          {location && locationId && (
            <WeatherLocationListItem__Star
              // We use the locationId we get from localStorage here to make sure the id exists when we add/remove from favourites
              // This was added to fix a bug we had where there was a difference between saved locationId and locationId from the API
              // Ref https://nrknyemedier.atlassian.net/browse/YR-5638
              locationId={locationId}
              locationName={location.name}
              isFavourite={type === 'favourited'}
            />
          )}
        </span>
        {warningList && (
          <span className="weather-location-list-item__warnings">
            <WarningsIconGroup warnings={warningList.warnings} stack={true} />
          </span>
        )}
        {forecast != null ? (
          <WeatherLocationListItem__Forecast dayIntervals={forecast.dayIntervals} columns={columns} />
        ) : null}
        {location && <WeatherLocationListItem__EditButton buttonId={buttonId} onClick={onEditPanelOpen} />}
        {location && locationId && open && (
          <WeatherLocationListEditPanel
            // We use the locationId we get from localStorage here to make sure the id exists when we add/remove from favourites
            // This was added to fix a bug we had where there was a difference between saved locationId and locationId from the API
            // Ref https://nrknyemedier.atlassian.net/browse/YR-5638
            locationId={locationId}
            locationName={location.name}
            openedById={buttonId}
            isAtTop={index === 0}
            type={type}
            onCancel={onEditPanelCancel}
            onClose={onEditPanelClose}
            onEditCustomName={() => {
              onEditLocationName();
              onEditPanelCancel();
            }}
          />
        )}
      </div>
    </div>
  );
}
