import { useLocationList } from '../../contexts/LocationListContext/LocationListContext';
import { useLogin } from '../../contexts/LoginContext/LoginContext';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { TUserLocationType } from '../../model/userLocation';
import { Button } from '../Button/Button';
import { OverlayPanel } from '../OverlayPanel/OverlayPanel';
import './WeatherLocationListEditPanel.scss';

interface IProps {
  locationId: string;
  locationName: string;
  openedById: string;
  type: TUserLocationType;
  isAtTop: boolean;
  onCancel: () => void;
  onClose: () => void;
  onEditCustomName: () => void;
}

export function WeatherLocationListEditPanel(props: IProps) {
  const { locationId, locationName, openedById, type, isAtTop, onCancel, onClose, onEditCustomName } = props;
  const { isLoggedIn } = useLogin();

  const translate = useTranslate();
  const {
    favouritedLocationIds,
    addLocationToFavorites,
    removeFavouritedLocation,
    removeVisitedLocation,
    removePrepopulatedLocation,
    updateLocationFavorites
  } = useLocationList();

  const isFavourite = type === 'favourited';

  function handlePrioritizeFavouriteClick() {
    if (isLoggedIn === true) {
      const favouritedLocationIdsWihthoutLocationdId = favouritedLocationIds.filter(id => {
        return locationId !== id;
      });
      favouritedLocationIdsWihthoutLocationdId.unshift(locationId);
      updateLocationFavorites({ locationIds: favouritedLocationIdsWihthoutLocationdId });
    } else {
      addLocationToFavorites({
        locationId,
        locationName,
        translate
      });
    }

    onClose();
  }

  function handleAddFavouriteClick() {
    addLocationToFavorites({
      locationId,
      locationName,
      translate
    });

    onClose();
  }

  function handleRemoveFavouriteClick() {
    removeFavouritedLocation({
      locationId,
      locationName,
      translate
    });

    onClose();
  }

  function handleRemoveVisitedClick() {
    removeVisitedLocation(locationId);

    onClose();
  }

  function handleRemovePrepopulatedClick() {
    removePrepopulatedLocation(locationId);
    onClose();
  }

  function handleRemoveClick() {
    if (type === 'favourited') {
      handleRemoveFavouriteClick();
      return;
    }

    if (type === 'visited') {
      handleRemoveVisitedClick();
      return;
    }

    handleRemovePrepopulatedClick();
  }

  return (
    <OverlayPanel openedById={openedById} ariaLabel={translate('nav/suggestionLabel')} onClose={onCancel}>
      <div className="weather-location-list-edit-panel">
        {isFavourite === true && isAtTop === false && (
          <div className="weather-location-list-edit-panel__menu-item">
            <Button
              as="button"
              className="weather-location-list-edit-panel__menu-button"
              iconBefore="icon-chevron-up"
              onClick={handlePrioritizeFavouriteClick}
            >
              {translate('weatherLocationListEditPanel/prioritizeButtonLabel')}
            </Button>
          </div>
        )}

        {isFavourite === false && (
          <div className="weather-location-list-edit-panel__menu-item">
            <Button
              as="button"
              className="weather-location-list-edit-panel__menu-button"
              iconBefore="icon-star"
              onClick={handleAddFavouriteClick}
            >
              {translate('weatherLocationListEditPanel/favouriteButtonLabel')}
            </Button>
          </div>
        )}

        <div className="weather-location-list-edit-panel__menu-item">
          <Button
            as="button"
            buttonVariant="critical"
            className="weather-location-list-edit-panel__menu-button"
            iconBefore="icon-dustbin"
            onClick={handleRemoveClick}
          >
            {translate('weatherLocationListEditPanel/deleteButtonLabel')}
          </Button>
        </div>

        <div className="weather-location-list-edit-panel__menu-item">
          <Button
            as="button"
            buttonType="bleed"
            buttonVariant="neutral"
            className="weather-location-list-edit-panel__menu-button"
            iconBefore="icon-edit"
            onClick={onEditCustomName}
          >
            {translate('login/weatherLocationListEditPanel/editCustomNameButtonLabel')}
          </Button>
        </div>
      </div>
    </OverlayPanel>
  );
}
