import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { ArticleList } from '../../components/ArticleList/ArticleList';
import { ContentHeading } from '../../components/ContentHeading/ContentHeading';
import { ExternalServices } from '../../components/ExternalServices/ExternalServices';
import { FeaturedContent } from '../../components/FeaturedContent/FeaturedContent';
import { Heading } from '../../components/Heading/Heading';
import { PageRoot } from '../../components/PageRoot/PageRoot';
import { StaticSearchForm } from '../../components/StaticSearchForm/StaticSearchForm';
import { Text } from '../../components/Text/Text';
import { WeatherLocationList } from '../../components/WeatherLocationList/WeatherLocationList';
import { useLocationList } from '../../contexts/LocationListContext/LocationListContext';
import { useFetchArticles } from '../../data/articles/hooks';
import { useTranslate } from '../../lib/hooks/useTranslate';
import './HomePage.scss';

export default function HomePage() {
  const translate = useTranslate();
  const articles = useFetchArticles();
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const {
    initialTotalFavouritedLocations,
    initialTotalVisitedLocations,
    favouritedLocationIds,
    visitedLocationIds,
    prepopulatedLocationIds,
    isLocationListInitialized
  } = useLocationList();

  const { isFirstRender } = useAppState();

  useEffect(() => {
    setLocationIds([...favouritedLocationIds, ...visitedLocationIds, ...prepopulatedLocationIds]);
  }, [isLocationListInitialized, favouritedLocationIds, visitedLocationIds, prepopulatedLocationIds]);

  function renderChildren() {
    //console.log('locationIds: ', locationIds);
    const initialLocationsCount = initialTotalFavouritedLocations + initialTotalVisitedLocations;

    // During the first render or if locationlist not has been initialized we want to render empty locations based on `initialLocationsCount`,
    // but after the first render we rely on the actual number of user locations.
    const hasLocations =
      ((isFirstRender || isLocationListInitialized === false) && initialLocationsCount > 0) || locationIds.length > 0;

    return (
      <>
        <div
          className={classNames({
            'home-page__background--with-locations': hasLocations
          })}
        />

        <ContentHeading hidden={true}>{translate('homePage/title')}</ContentHeading>

        <div className="home-page__container">
          <div className="home-page__locations">
            <div className="layout-container layout-container--with-padding">
              <Heading level="h2" size="2">
                {translate('homePage/locations/title')}
              </Heading>
            </div>
            {hasLocations ? (
              <div className="layout-container">
                <WeatherLocationList initialLocationsCount={initialLocationsCount} />
              </div>
            ) : (
              <div className="layout-container layout-container--with-padding">
                <Text className="home-page__empty-list" as="p" size="4">
                  {translate('homePage/locations/empty')}
                </Text>
              </div>
            )}
          </div>
        </div>

        <div className="home-page__container">
          <div className="layout-container">
            <div className="home-page__static">
              <Heading level="h2" size="1" className="home-page__static-heading">
                {translate('homePage/static/title')}
              </Heading>

              <div className="home-page__static-search">
                <StaticSearchForm />
              </div>
            </div>
          </div>
        </div>

        <div className="home-page__container">
          <div className="home-page__featured-content">
            <div className="layout-container layout-container--with-padding">
              <Heading level="h2" size="2" hidden={true}>
                {translate('homePage/featuredContent/title')}
              </Heading>

              <FeaturedContent />
            </div>
          </div>
        </div>

        {articles.data != null && articles.data.length > 0 && (
          <div className="home-page__container">
            <div className="layout-container layout-container--with-padding">
              <Heading level="h2" size="2" marginBottom={2}>
                {translate('homePage/article/title')}
              </Heading>
            </div>

            <div className="layout-container layout-container--with-padding">
              <ArticleList articles={articles.data} />
            </div>
          </div>
        )}

        <div className="layout-container layout-container--with-padding">
          <ExternalServices
            title={translate('homePage/externalServices/title')}
            items={[
              {
                text: translate('homePage/externalServices/subjectiveForecast/label'),
                href: translate('homePage/externalServices/subjectiveForecast/url')
              },
              {
                text: translate('homePage/externalServices/textForecast/label'),
                href: translate('homePage/externalServices/textForecast/url')
              },
              {
                text: translate('homePage/externalServices/satelliteImages/label'),
                href: translate('homePage/externalServices/satelliteImages/url')
              }
            ]}
          />
        </div>
      </>
    );
  }

  return <PageRoot className="home-page" renderChildren={renderChildren} />;
}
