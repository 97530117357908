import yrTime from '@nrk/yr-time';
import { useQueries } from '@tanstack/react-query';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { useLocationList } from '../../contexts/LocationListContext/LocationListContext';
import { queryForecastByLocationId } from '../../data/forecast/queries';
import { getLocalStorageString } from '../../lib/helpers/localStorage';
import { createTimeLabel } from '../../lib/helpers/time';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { IForecast } from '../../model/forecast';
import { ITranslateFunction } from '../../model/translate';
import { IUserLocation } from '../../model/userLocation';
import { Text } from '../Text/Text';
import { WeatherLocationListItem } from '../WeatherLocationListItem/WeatherLocationListItem';
import './WeatherLocationList.scss';
import EditCustomLocationName from '../EditCustomLocationName/EditCustomLocationName';

interface IProps {
  initialLocationsCount: number;
}

export function WeatherLocationList(props: IProps) {
  const { initialLocationsCount } = props;

  const { isFirstRender } = useAppState();
  const [editLocationId, setEditLocationId] = useState<string | null>();

  const translate = useTranslate();

  const editLocationIdFromLocalStorage = getLocalStorageString('editLocationId');

  useEffect(() => {
    if (editLocationIdFromLocalStorage != null) {
      setEditLocationId(editLocationIdFromLocalStorage);
    }
  }, [editLocationIdFromLocalStorage]);

  const {
    favouritedLocationIds,
    visitedLocationIds,
    prepopulatedLocationIds,
    customLocationNames,
    isLocationListInitialized
  } = useLocationList();

  const userLocations = createUserLocations({ favouritedLocationIds, visitedLocationIds, prepopulatedLocationIds });
  const forecastResults = useQueries({
    queries: userLocations.map(userLocation => {
      return queryForecastByLocationId({ locationId: userLocation.locationId });
    })
  });

  // Determine which forecast has the earliest start day.
  // This day becomes the first column in the weather location list.
  // This will result in the first column being empty for certain locations if they have
  // a forecast that starts on a later day either because of time zones or caching reasons.
  const earliestDay = useMemo(() => {
    const forecasts = forecastResults.map(forecastResult => forecastResult.data);

    return getEarliestDay(forecasts);
  }, [forecastResults]);

  const columns = createColumns({ isFirstRender, earliestDay, translate });

  // During the first render we want to render empty locations based on `initialLocationsCount`
  const firstRenderLocations = Array(initialLocationsCount).fill(undefined);

  return (
    <div className="weather-location-list">
      <div className="weather-location-list__heading-row" aria-hidden={true}>
        <div className="weather-location-list__heading-days">
          {columns.map((column, idx) => (
            <div
              key={idx}
              className={classNames({
                'weather-location-list__heading-day-cell': true,
                'weather-location-list__heading-day-cell--loading': isFirstRender
              })}
            >
              <Text size="5" color="secondary" className="weather-location-list__heading-day-string-short">
                {column.short}
              </Text>
              <Text size="5" color="secondary" className="weather-location-list__heading-day-string-long">
                {column.long}
              </Text>
            </div>
          ))}
        </div>
      </div>

      {isLocationListInitialized === false
        ? firstRenderLocations.map((_location, index) => (
            <WeatherLocationListItem key={index} columns={columns} index={index} type="visited" />
          ))
        : userLocations.map((userLocation, index) => {
            const { type, locationId } = userLocation;
            const customNameObject = customLocationNames.find(
              customLocationName => customLocationName.locationId === locationId
            );

            const customName = customNameObject != null ? customNameObject.name : undefined;
            return (
              <WeatherLocationListItem
                key={locationId}
                type={type}
                index={index}
                locationId={locationId}
                columns={columns}
                customLocationName={customName}
                setEditLocationId={(editLocationId: string) => {
                  setEditLocationId(editLocationId);
                }}
              />
            );
          })}
      {editLocationId != null && (
        <EditCustomLocationName
          trackingLabel="location-list"
          locationId={editLocationId}
          handleClose={() => {
            setEditLocationId(null);
          }}
        />
      )}
    </div>
  );
}

function getEarliestDay(forecasts: Array<IForecast | undefined>) {
  let earliestDay: yrTime.YrTime | undefined;

  for (const forecast of forecasts) {
    if (forecast == null) {
      continue;
    }

    const forecastStartDay = yrTime.create(forecast.dayIntervals[0].start);
    if (earliestDay == null || forecastStartDay.format('YYYY-MM-DD') < earliestDay.format('YYYY-MM-DD')) {
      earliestDay = forecastStartDay;
    }
  }

  return earliestDay;
}

export function createColumns({
  isFirstRender,
  earliestDay,
  translate
}: {
  isFirstRender: boolean;
  earliestDay?: yrTime.YrTime;
  translate: ITranslateFunction;
}): Array<{ time?: string; short: string; long: string }> {
  if (isFirstRender || earliestDay == null) {
    return [
      { time: undefined, short: '-', long: '-' },
      { time: undefined, short: '-', long: '-' },
      { time: undefined, short: '-', long: '-' },
      { time: undefined, short: '-', long: '-' }
    ];
  }

  const startOfEarliestDay = earliestDay.startOf('day');

  const days = [
    startOfEarliestDay,
    startOfEarliestDay.add(1, 'day'),
    startOfEarliestDay.add(2, 'day'),
    startOfEarliestDay.add(3, 'day')
  ];

  return days.map(dayYrTime => {
    return {
      time: dayYrTime.timeString,
      short: createTimeLabel({
        time: dayYrTime.timeString,
        type: 'day-short',
        translate,
        transform: 'sentence-case'
      }),
      long: createTimeLabel({
        time: dayYrTime.timeString,
        type: 'day-long',
        translate,
        transform: 'sentence-case'
      })
    };
  });
}

function createUserLocations({
  favouritedLocationIds,
  visitedLocationIds,
  prepopulatedLocationIds
}: {
  favouritedLocationIds: string[];
  visitedLocationIds: string[];
  prepopulatedLocationIds: string[];
}) {
  const userLocations: IUserLocation[] = [];

  favouritedLocationIds.forEach(locationId => {
    userLocations.push({ type: 'favourited', locationId });
  });

  visitedLocationIds.forEach(locationId => {
    userLocations.push({ type: 'visited', locationId });
  });

  prepopulatedLocationIds.forEach(locationId => {
    userLocations.push({ type: 'prepopulated', locationId });
  });

  return userLocations;
}
