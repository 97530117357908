import { TIconId } from '@nrk/yr-icons';
import { createPageUrl } from '../../app/urls';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useMapSubpageId } from '../../lib/hooks/useMapSubpageId';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Button } from '../Button/Button';

type TFeaturedContentItemType = 'map' | 'weather-warnings' | 'mountain-passes' | 'water-temperatures' | 'snow-depths';

interface IInternalProps {
  type: TFeaturedContentItemType;
}

interface IExternalProps {
  type: 'external';
  url: string;
  text?: string;
}

interface IFeaturedContentItem {
  icon: TIconId;
  localeKey: string;
  url: {
    pageId: string;
    subpageId?: string;
  };
}

export function FeaturedContentButton(props: IInternalProps | IExternalProps) {
  const { type } = props;
  const translate = useTranslate();
  const mapSubpageId = useMapSubpageId();

  const localeCode = useLocaleCode();

  const FEATURED_CONTENT_TYPES: { [key in TFeaturedContentItemType]: IFeaturedContentItem } = {
    map: {
      icon: 'icon-map',
      localeKey: 'featuredContent/items/map',
      url: {
        pageId: 'map',
        subpageId: mapSubpageId
      }
    },
    'weather-warnings': {
      icon: 'icon-warning',
      localeKey: 'featuredContent/items/weatherWarnings',
      url: {
        pageId: 'weather-warnings'
      }
    },
    'water-temperatures': {
      icon: 'icon-temperature',
      localeKey: 'featuredContent/items/waterTemperatures',
      url: {
        pageId: 'water-temperatures-region-list'
      }
    },
    'mountain-passes': {
      icon: 'icon-mountain',
      localeKey: 'featuredContent/items/mountainPasses',
      url: {
        pageId: 'mountain-passes',
        subpageId: 'south'
      }
    },
    'snow-depths': {
      icon: 'icon-snow',
      localeKey: 'featuredContent/items/snowDepths',
      url: {
        pageId: 'snow-depth-region-list'
      }
    }
  };

  if (type === 'external') {
    const { url, text } = props;
    return (
      <Button as="link" buttonSize="large" href={url} iconAfter="icon-new-window" isExternal={true}>
        {text}
      </Button>
    );
  }

  const buttonContent = FEATURED_CONTENT_TYPES[type];

  return (
    <Button
      as="link"
      buttonSize="large"
      href={createPageUrl({ localeCode, ...buttonContent.url })}
      iconBefore={buttonContent.icon}
    >
      {translate(buttonContent.localeKey)}
    </Button>
  );
}
