import { Icon } from '@nrk/yr-icons';
import { createPageUrl } from '../../app/urls';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Button } from '../Button/Button';
import './StaticSearchForm.scss';

/**
 * StaticSearchForm is also rendered in error pages where we don't have the ContextProvider available
 * Therefore we send localeCode as a prop instead of using context
 */
export const StaticSearchForm = () => {
  const localeCode = useLocaleCode();
  const translate = useTranslate();

  const action = createPageUrl({ localeCode, pageId: 'search' });

  return (
    <form action={action} autoComplete="off" className="static-search-form" method="get">
      <div className="static-search-form__input-container">
        <input
          autoCapitalize="none"
          autoComplete="off"
          autoCorrect="off"
          className="static-search-form__input"
          placeholder={translate('staticSearchForm/placeholder')}
          name="q"
        />

        <Icon id="icon-search" className="static-search-form__icon" size={3} />
      </div>

      <Button as="button" className="static-search-form__submit-button">
        {translate('staticSearchForm/button')}
      </Button>
    </form>
  );
};
