import { Icon } from '@nrk/yr-icons';
import { useTranslate } from '../../lib/hooks/useTranslate';

interface IProps {
  buttonId: string;
  onClick: () => void;
}

export const WeatherLocationListItem__EditButton = (props: IProps) => {
  const { buttonId, onClick } = props;
  const translate = useTranslate();

  return (
    <button
      id={buttonId}
      className="weather-location-list-item__edit-button"
      aria-label={translate('weatherLocationListItem/editButtonLabel')}
      onClick={event => {
        event.preventDefault();
        onClick();
      }}
    >
      <Icon id="icon-dots-vertical" size={3} />
    </button>
  );
};
